import axios from "axios";
import { store } from "..";
import { updateNotificationAction } from "../store/actions/widgetActions";
import packageJson from "../../package.json";
const version = packageJson.version;

export const Api_URL = process.env.REACT_APP_API_URL;
export const NetbankingUrl = process.env.REACT_APP_NETBANKING_URL;

export const Paybis_origin = process.env.REACT_APP_PAYBIS_ORIGIN;
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

export const axiosInstance = axios.create({
  baseURL: Api_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const params = {
    vw: version,
  };

  config.params = { ...config.params, ...params };
  return config;
});

export function getQueryParameters(url: string) {
  const params: any = {};
  const queryString = url.split("?")[1];

  if (queryString) {
    const keyValuePairs = queryString.split("&");
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      if (key && value) {
        // Decode URI components if needed
        params[key] = decodeURIComponent(value);
      }
    });
  }

  return params;
}

export const Delimiter = (number: number) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export function getPairsKeyValue(state: any, pair: string, key: string) {
  const pairData = state && state.pairData;
  if (pairData) {
    const currentPairData = pairData.find((ele: any) => ele.pairName === pair);
    if (currentPairData) {
      return currentPairData[key];
    }
  }
  return null;
}

export function getTokensKeyValue(state: any, pair: string, key: string) {
  const tokenData = state && state.tokenData;
  if (tokenData) {
    const currentTokenData = tokenData.find(
      (ele: any) => ele.pairName === pair,
    );
    if (currentTokenData) {
      return currentTokenData[key];
    }
  }
  return null;
}

export const PadTo2 = (x: string | number) => {
  x = `${x}`;
  if (x.length <= 1) return `0${x}`;
  return x;
};

//Dynamic Text:
export function translateMessage(id: string) {
  const lang = localStorage.getItem("lang") || "en";
  const text = GetTextJson(lang)[id] ? GetTextJson(lang)[id] : id;
  return text;
}

const GetTextJson = (lang: any) => {
  return require(`../assets/texts/${lang}.json`);
};

export const GetLogosrc = (name: string): string => {
  return `${ASSET_URL}/${name.toUpperCase()}.png`;
};

export const CRYPTO_LOGO_FALLBACK_IMAGE = `${ASSET_URL}/DEFAULT.png`;

// Number Display

export const standardDigitConversion = (val: any) => {
  val = RemoveExpo(val);

  let split = val.toString().split(".");

  if (split.length === 1) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    let [whole, frac] = split;
    whole = whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${whole}.${frac}`;
  }
};

export const RemoveExpo = (x: any) => {
  var data = String(x).split(/[eE]/);
  if (data.length == 1) return data[0];

  var z = "",
    sign = x < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^\-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};

export const RoundUptoSignificant = (
  n: number | string,
  sig = 3,
  minDecimal = 2,
): string => {
  const nSplit = RemoveExpo(n).split(".");
  if (nSplit.length !== 2) return RemoveExpo(n);
  const wholeNumber = nSplit[0];
  const decimalNumber = nSplit[1];
  const wholeNumberLength = wholeNumber.length;

  if (parseFloat(wholeNumber) === 0) {
    let lastIndex = 0;

    for (let i = 0; i < nSplit[1].length; i++) {
      const ch = nSplit[1][i];
      if (ch !== "0") {
        lastIndex = i;
        break;
      }
    }

    return nSplit.join(".").slice(0, wholeNumberLength + 1 + lastIndex + sig);
  } else {
    const remSig =
      sig > wholeNumberLength ? sig - wholeNumberLength : minDecimal;

    return nSplit.join(".").slice(0, wholeNumberLength + 1 + remSig);
  }
};

export const SanitizeNumber = (n: any): any => {
  if (isNaN(parseFloat(`${n}`))) {
    return 0;
  }
  return n;
};

export const FormatNumber = (
  x: number | string,
  sig = 8,
  minDecimal = 0,
): string => {
  x = SanitizeNumber(x);
  x = RoundUptoSignificant(x, sig, minDecimal);
  return standardDigitConversion(parseFloat(x));
};

export const FormatDate = (dateStr: string) => {
  let date = new Date(dateStr);
  return `${PadTo2(date.getDate())}-${PadTo2(date.getMonth() + 1)}-${date.getFullYear()}`;
};

export const FormatDateTime = (dateStr: string) => {
  let date = new Date(dateStr);
  return `${PadTo2(date.getDate())}-${PadTo2(date.getMonth() + 1)}-${date.getFullYear()} ${PadTo2(date.getHours())}:${PadTo2(date.getMinutes())}`;
};

// Dispatch notifications

export const dispatchNotifications = (str: INotificationState) => {
  const color =
    store.getState().widgetReducer.paymentNotification.color === "green"
      ? "green"
      : "default";
  store.dispatch(updateNotificationAction(str));
  setTimeout(
    () =>
      store.dispatch(updateNotificationAction({ message: "", color: color })),
    1500,
  );
};

// Copy Functions with notification alert

export const copyClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  dispatchNotifications({
    message: "TEXT COPIED!",
    color: "blue",
  });
};

export const POLL_INTERVAL = 3000;

export const ParseErrorMessage = (resp: any): string => {
  try {
      if (resp?.data?.error?.message) {
          return `${resp.data.error.field}:${resp.data.error.message}`;
      }
      if (resp?.statusText) {
          return resp.statusText;
      }
  } catch (e) {
      console.error("Error parsing response:", e);
  }
  return "An unexpected error occurred. Please try again.";
};