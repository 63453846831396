import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import BackBar from "./BackBar";
import { useSelector } from "react-redux";
import {
  axiosInstance,
  dispatchNotifications,
  ParseErrorMessage,
} from "../../helper/constant";
import { Spinner } from "react-bootstrap";

interface FormData {
  firstName: string;
  lastName: string;
  card_number: string;
  expires: string;
  remember_card: boolean;
  email: string;
  cvc: string;
  address: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  phoneNumber: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  card_number?: string;
  expires?: string;
  email?: string;
  cvc?: string;
  //
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  phoneNumber?: string;
}

const MasterVisaCustomerForm: React.FC = () => {
  let isMounted = false;

  const [show3ds, setShow3ds] = useState("");
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const [browserInfo, setBrowserInfo] = useState({
    remote_ip: "",
    user_agent: "",
    accept_header: "",
    language: "",
    java_enabled: false,
    javascript_enabled: true,
    color_depth: 0,
    utc_offset: 0,
    screen_width: 0,
    screen_height: 0,
  });

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    card_number: "",
    expires: "",
    remember_card: false,
    email: "",
    cvc: "",
    //
    country: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    phoneNumber: "",
  });

  const [showCvvCode, setShowCvvCode] = useState(false);

  useEffect(() => {
    const fetchRemoteIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        return "";
      }
    };

    const gatherBrowserInfo = async () => {
      const ip = await fetchRemoteIp();

      setBrowserInfo({
        remote_ip: ip || "",
        user_agent: navigator.userAgent || "",
        accept_header: navigator.userAgent.includes("Firefox")
          ? "text/html,application/xhtml+xml,application/xml"
          : "text/html",
        language: navigator.language || navigator.languages?.[0] || "en-US",
        java_enabled: navigator.javaEnabled ? navigator.javaEnabled() : false,
        javascript_enabled: true,
        color_depth: window.screen.colorDepth || 24,
        utc_offset: new Date().getTimezoneOffset(),
        screen_width: window.screen.width || 0,
        screen_height: window.screen.height || 0,
      });
    };

    gatherBrowserInfo();
  }, []);

  const [errors, setErrors] = useState<FormErrors>({});

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === "checkbox" ? checked : value,
    });
    validateFormOnChange(id, value);
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "first name is required.";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "last name is required.";
    }

    if (!/^\d{16}$/.test(formData.card_number)) {
      newErrors.card_number = "Card number must be 16 digits.";
    }

    if (!/^\d{2}\/\d{2}$/.test(formData.expires)) {
      newErrors.expires = "Invalid date format.";
    }

    if (!/^\d{3,4}$/.test(formData.cvc)) {
      newErrors.cvc = "CVV must be 3 or 4 digits.";
    }

    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Valid email is required.";
    }

    // New fields
    if (!formData.address?.trim()) {
      newErrors.address = "Address is required.";
    }

    if (!formData.country?.trim()) {
      newErrors.country = "Country is required.";
    }

    if (!formData.state?.trim()) {
      newErrors.state = "State is required.";
    }

    if (!formData.city?.trim()) {
      newErrors.city = "City is required.";
    }

    if (!formData.zip?.trim() || !/^\d{6}(-\d{4})?$/.test(formData.zip)) {
      newErrors.zip = "Invalid ZIP code format.";
    }

    if (
      !formData.phoneNumber?.trim() ||
      !/^\d{10}$/.test(formData.phoneNumber)
    ) {
      newErrors.phoneNumber = "Phone number must be 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFormOnChange = (name: string, value: any): void => {
    const newErrors: FormErrors = {};

    if (name === "firstName") {
      if (name === "firstName" && !value.trim()) {
        newErrors.firstName = "First name is required.";
      } else {
        newErrors.firstName = "";
      }
    }

    if (name === "lastName") {
      if (name === "lastName" && !value.trim()) {
        newErrors.lastName = "Last name is required.";
      } else {
        newErrors.lastName = "";
      }
    }

    if (name === "card_number") {
      if (name === "card_number" && !/^\d{16}$/.test(value)) {
        newErrors.card_number = "Card number must be 16 digits.";
      } else {
        newErrors.card_number = "";
      }
    }

    if (name === "expires") {
      if (name === "expires" && !/^\d{2}\/\d{2}$/.test(value)) {
        newErrors.expires = "Invalid date format.";
      } else {
        newErrors.expires = "";
      }
    }

    if (name === "cvc") {
      if (name === "cvc" && !/^\d{3,4}$/.test(value)) {
        newErrors.cvc = "CVV must be 3 or 4 digits.";
      } else {
        newErrors.cvc = "";
      }
    }

    if (name === "email") {
      if (name === "email" && (!value.trim() || !/\S+@\S+\.\S+/.test(value))) {
        newErrors.email = "Valid email is required.";
      } else {
        newErrors.email = "";
      }
    }

    // New fields
    if (name === "address") {
      if (name === "address" && !value.trim()) {
        newErrors.address = "Address is required.";
      } else {
        newErrors.address = "";
      }
    }

    if (name === "country") {
      if (name === "country" && !value.trim()) {
        newErrors.country = "Country is required.";
      } else {
        newErrors.country = "";
      }
    }

    if (name === "state") {
      if (name === "state" && !value.trim()) {
        newErrors.state = "State is required.";
      } else {
        newErrors.state = "";
      }
    }

    if (name === "city") {
      if (name === "city" && !value.trim()) {
        newErrors.city = "City is required.";
      } else {
        newErrors.city = "";
      }
    }

    if (name === "zip") {
      if (
        name === "zip" &&
        (!value.trim() || !/^\d{6}(-\d{4})?$/.test(value))
      ) {
        newErrors.zip = "Invalid ZIP code format.";
      } else {
        newErrors.zip = "";
      }
    }

    if (name === "phoneNumber") {
      if (
        name === "phoneNumber" &&
        (!value.trim() || !/^\d{10}$/.test(value))
      ) {
        newErrors.phoneNumber = "Phone number must be 10 digits.";
      } else {
        newErrors.phoneNumber = "";
      }
    }

    setErrors({ ...errors, ...newErrors });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form and browser info:", { ...formData, ...browserInfo });

      setIsSubmitting(true);
      const [month, year] = formData.expires.split("/");

      const data = {
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,

        //

        saveMyCard: formData.remember_card,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        zip: formData.zip,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        cardNumber: formData.card_number,
        cardExpiryMonth: month,
        cardExpiryYear: `20${year}`,
        cardCvvNumber: formData.cvc,
      };

      axiosInstance
        .post("/widget/initiate-pay-with-card", { ...data })
        .then((resp) => {
          // handle 3ds if required else show success

          console.log("resp", resp.data);

          const data = resp.data.data;

          if (data.require3ds) {
            // embed iframe
            dispatchNotifications({
              message: "Please verify this transaction",
              color: "blue",
            });

            setShow3ds(data["3dsUrl"]);

            window.open(data["3dsUrl"], "_blank");
          } else {
            // toggle success screen
            dispatchNotifications({
              message: "Payment Successful!",
              color: "green",
            });

            setShowPaymentSuccess(true);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data) {
            const message = ParseErrorMessage(e.response);

            dispatchNotifications({
              message: message,
              color: "red",
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleShowCVV = () => {
    setShowCvvCode(!showCvvCode);
  };

  const formPanel = (
    <>
      <div className="master-visa-payment-form">
        <h2>Payment Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name as per card"
              onBlur={() =>
                validateFormOnChange("firstName", formData.firstName)
              }
              // required
            />
            {errors.firstName && (
              <small className="error-text">{errors.firstName}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Last Name as per card"
              onBlur={() => validateFormOnChange("lastName", formData.lastName)}
              // required
            />
            {errors.lastName && (
              <small className="error-text">{errors.lastName}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="card_number">Card Number</label>
            <input
              type="text"
              id="card_number"
              value={formData.card_number}
              onChange={handleInputChange}
              placeholder="1234 1234 1234 1234"
              onBlur={() =>
                validateFormOnChange("card_number", formData.card_number)
              }
              // required
            />
            {errors.card_number && (
              <small className="error-text">{errors.card_number}</small>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <div className="form-group" style={{ width: "49%" }}>
              <label htmlFor="expires">Expiration</label>
              <input
                type="text"
                id="expires"
                value={formData.expires}
                onChange={handleInputChange}
                placeholder="MM/YY"
                onBlur={() => validateFormOnChange("expires", formData.expires)}
                // required
              />
              {errors.expires && (
                <small className="error-text">{errors.expires}</small>
              )}
            </div>
            <div className="form-group" style={{ width: "49%" }}>
              <label htmlFor="cvc">CVV</label>
              <div className="position-relative">
                <input
                  type={showCvvCode ? "text" : "password"}
                  id="cvc"
                  value={formData.cvc}
                  onChange={handleInputChange}
                  placeholder="CVV"
                  onBlur={() => validateFormOnChange("cvc", formData.cvc)}
                  // required
                />
                <i
                  className={`icon fa ${
                    !showCvvCode ? "fa-eye-slash" : "fa-eye"
                  }`}
                  onClick={() => handleShowCVV()}
                ></i>
              </div>
              {/* (<i className={`fa ${this.state.showPassword ? 'fa-eye-slash' : 'fa-eye'}`} onClick={() => this.handleShowPassword()}></i>)} */}
              {errors.cvc && <small className="error-text">{errors.cvc}</small>}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={() => validateFormOnChange("email", formData.email)}
              placeholder="Email address"
              // required
            />
            {errors.email && (
              <small className="error-text">{errors.email}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="number"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              onBlur={() =>
                validateFormOnChange("phoneNumber", formData.phoneNumber)
              }
              placeholder="Phone Number"
              // required
            />
            {errors.phoneNumber && (
              <small className="error-text">{errors.phoneNumber}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="string"
              id="address"
              value={formData.address}
              onChange={handleInputChange}
              onBlur={() => validateFormOnChange("address", formData.address)}
              placeholder="Address"
              // required
            />
            {errors.address && (
              <small className="error-text">{errors.address}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select
              id="country"
              value={formData.country}
              onChange={(e:any) => handleInputChange(e)}
              onBlur={() => validateFormOnChange("country", formData.country)}
            >
              <option value="" disabled>
                Select your country
              </option>
              <option value="AF">Afghanistan</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BR">Brazil</option>
              <option value="BN">Brunei</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo - Brazzaville</option>
              <option value="CD">Congo - Kinshasa</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Côte d’Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czechia</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="SZ">Eswatini</option>
              <option value="ET">Ethiopia</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GR">Greece</option>
              <option value="GD">Grenada</option>
              <option value="GT">Guatemala</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HN">Honduras</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KR">South Korea</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Laos</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia</option>
              <option value="MD">Moldova</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestine</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="QA">Qatar</option>
              <option value="RO">Romania</option>
              <option value="RU">Russia</option>
              <option value="RW">Rwanda</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">São Tomé and Príncipe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syria</option>
              <option value="TW">Taiwan</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Vietnam</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
            {errors.country && (
              <small className="error-text">{errors.country}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="state">State</label>
            <input
              type="string"
              id="state"
              value={formData.state}
              onChange={handleInputChange}
              onBlur={() => validateFormOnChange("state", formData.state)}
              placeholder="state"
              // required
            />
            {errors.state && (
              <small className="error-text">{errors.state}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="city">city</label>
            <input
              type="string"
              id="city"
              value={formData.city}
              onChange={handleInputChange}
              onBlur={() => validateFormOnChange("city", formData.city)}
              placeholder="city"
              // required
            />
            {errors.city && <small className="error-text">{errors.city}</small>}
          </div>
          <div className="form-group">
            <label htmlFor="zip">zip</label>
            <input
              type="string"
              id="zip"
              value={formData.zip}
              onChange={handleInputChange}
              onBlur={() => validateFormOnChange("zip", formData.zip)}
              placeholder="zip"
              // required
            />
            {errors.zip && <small className="error-text">{errors.zip}</small>}
          </div>

          <div className="mb-1 remember-card">
            <input
              type="checkbox"
              id="remember_card"
              checked={formData.remember_card}
              onChange={handleInputChange}
            />
            <label htmlFor="remember_card">Remember this card across GlobiancePay checkouts</label>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="submit-button"
            >
              Pay Now
            </button>
          </div>
        </form>
      </div>
    </>
  );

  const three3dsPanel = (
    <>
      <div className="master-visa-payment-form">
        <h2>3DS Verification Required</h2>
        <h4>Please complete additional verification opened in the new tab. Once the payment is successful in the other tab, it will be automatically processed here! </h4>
      </div>
    </>
  );

  const successPanel = (
    <>
      <div className="master-visa-payment-form">
        <h2>Payment Successful</h2>
        <h4>Please wait a few seconds while we process this payment . . . </h4>
      </div>
    </>
  );

  let renderPanel = formPanel;

  if (show3ds) {
    renderPanel = three3dsPanel;
  }

  if (showPaymentSuccess) {
    renderPanel = successPanel;
  }

  return (
    <>
      <BackBar className="py-0" />
      {renderPanel}
    </>
  );
};

export default MasterVisaCustomerForm;
